import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Home from "./containers/Home";
import Service from "./containers/Service";
import Guide from "./containers/Guide";
import Company from "./containers/Company";
import Fare from "./containers/Fare";
import Terms from "./containers/Terms";
import Benefit from "./containers/Benefit";
import Faq from "./containers/Faq";
import Body from "./components/common/Body";
import { irSite } from "./utils/constant";

const App = () => {
  // 이전에 사용되던 주요약관 링크를 바로 리다이렉트 하도록 처리
  switch (document.location.pathname) {
    case "/privacy":
      document.location.replace(
        "https://socar-docs.zendesk.com/hc/ko/articles/360048398254"
      );
      return null;
    case "/terms-gis":
      document.location.replace(
        "https://socar-docs.zendesk.com/hc/ko/articles/360049150553"
      );
      return null;
    case "/rent_terms":
      document.location.replace(
        "https://socar-docs.zendesk.com/hc/ko/articles/360049150593"
      );
      return null;
    case "/ir":
      document.location.replace(irSite);
      return null;
  }

  return (
    <div className="App">
      <Header />
      <Body>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/service" component={Service} />
            <Route path="/guide" component={Guide} />
            <Route path="/fare" component={Fare} />
            <Route path="/benefit" component={Benefit} />
            <Route path="/company" component={Company} />
            <Route path="/terms" component={Terms} />
            <Route path="/cs" component={Faq} />
            <Redirect path="/*" to="/" />
          </Switch>
        </BrowserRouter>
      </Body>
      <Footer />
    </div>
  );
};

export default App;
