import React, { useState } from "react";

const Company = () => {
  const [chacareSelect, chacareSetSelect] = useState(false);
  const [ridefluxSelect, ridefluxSetSelect] = useState(false);
  const [vcncSelect, vcncSetSelect] = useState(false);
  const [nine2oneSelect, nine2oneSetSelect] = useState(false);
  const [moduCompanySelect, moduCompanySetSelect] = useState(false);
  const [btnSelect, btnSetSelect] = useState(false);

  const toggleClass = (e: any) => {
    chacareSetSelect(false);
    ridefluxSetSelect(false);
    vcncSetSelect(false);
    nine2oneSetSelect(false);
    moduCompanySetSelect(false);

    switch (e.currentTarget.className) {
      case "chacare":
        chacareSetSelect(!chacareSelect);
        break;
      case "rideflux":
        ridefluxSetSelect(!ridefluxSelect);
        break;
      case "vcnc":
        vcncSetSelect(!vcncSelect);
        break;
      case "nine2one":
        nine2oneSetSelect(!nine2oneSelect);
        break;
      case "modu-company":
        moduCompanySetSelect(!moduCompanySelect);
        break;
      default:
        break;
    }
  };

  const toggleBtnClass = (e: any) => {
    btnSetSelect(!btnSelect);
  };

  return (
    <div id="company">
      <main className="main">
        <div className="wrap">
          <h1>
            우리는 모든 사람이
            <br /> 자유롭고 행복하게
            <br /> 이동하는 세상을 만듭니다
          </h1>
          <div className="bg"></div>
        </div>
      </main>
      <section className="core-values">
        <div className="wrap">
          <div className="section-title">
            <h2>Core Values</h2>
            <p>쏘카다운 방식으로 이동을 혁신합니다</p>
          </div>
          <ul>
            <li className="core-value-01">
              <h3>
                이동 수단이 아닌
                <br /> 이용자를 중심으로 생각합니다
              </h3>
              <p className="underline">
                이용자에게 꼭 필요한 이동 서비스를 제공하고 신뢰를 바탕으로
                장기적인 관계를 형성하기 위해 노력합니다.{" "}
              </p>
            </li>
            <li className="core-value-02">
              <h3>임팩트가 더 큰 일을 우선합니다</h3>
              <p className="underline">
                쏘카가 지향하는 이동의 미래와 가치를 만드는 과정에서 임팩트가 더
                큰 일을 우선으로 합니다.
              </p>
            </li>
            <li className="core-value-03">
              <h3>
                대담하게 생각하고
                <br /> 과감하게 행동합니다
              </h3>
              <p>
                문제를 새롭게 바라보고 관습에 과감히 도전합니다. 익숙한 관점,
                편한 방식에 매몰되지 않고, 꼭 필요하고
                <br className="non-desktop-br" /> 중요한 일이면 실패 가능성을
                감수하고 도전합니다.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="summary">
        <h2 className="a11y-hidden">한눈에 보는 쏘카</h2>
        <div className="summary-bg"></div>
        <div className="text-box">
          <dl className="socar-subscriber">
            <dt>쏘카 누적 가입자 수</dt>
            <dd>
              <span>0</span>만 명 +{" "}
            </dd>
          </dl>
          <dl className="membership-subscriber">
            <dt>멤버십 누적 구독 건수</dt>
            <dd>
              <span>0</span>만 건 +{" "}
            </dd>
          </dl>
          <dl className="investment">
            <dt>누적 투자 유치액</dt>
            <dd>
              <span>0</span>억 원 +{" "}
            </dd>
          </dl>
          <dl className="growth-rate">
            <dt>연평균 성장률 (최근 10년)</dt>
            <dd>
              <span>0</span>%{" "}
            </dd>
          </dl>
        </div>
      </section>
      <section className="next-move">
        <div className="wrap">
          <h2 className="section-title">Next Move</h2>
          <p>
            기술과 데이터로 <br /> 내일의 이동을 실현합니다
          </p>

          <ul>
            <li className="next-move-01">
              <div className="text-box">
                <h3>스트리밍 모빌리티</h3>
                <p>
                  스트리밍 서비스로 시공간의
                  <br /> 제약 없이 음악을 즐기는 것처럼
                  <br /> 이동 수단을 소유하지 않아도
                  <br /> 누구나 언제 어디서든 편리하고 자유롭게
                  <br /> 이동할 수 있는 모빌리티 서비스로 진화합니다.
                </p>
              </div>
              <div className="next-move-img"></div>
            </li>
            <li className="next-move-02">
              <div className="text-box">
                <h3>경험 플랫폼</h3>
                <p>
                  자동차를 나만의 경험 공간으로 구현하여
                  <br /> 이용자가 자가용에서 얻을 수 있는 경험,
                  <br /> 그 이상을 제시합니다. 이동하는 순간뿐 아니라 출발하기
                  전부터 도착한 이후까지
                  <br /> 총체적인 이동 경험을 매끄럽게 디자인합니다.
                </p>
              </div>
              <div className="next-move-img"></div>
            </li>
            <li className="next-move-03">
              <div className="text-box">
                <h3>테크 플랫폼</h3>
                <p>
                  새로운 이동 기술을 가장 먼저 서비스화하는
                  <br /> 모빌리티 플랫폼을 지향합니다. 또한
                  <br /> 자동차와 사람의 데이터를 종합적으로 활용해
                  <br /> 이동 수단을 보다 체계적으로 운영하고,
                  <br /> AI 기술로 더 안전한 이동 서비스를 제공합니다.
                </p>
              </div>
              <div className="next-move-img"></div>
            </li>
          </ul>
          <div className="video-wrap">
            <iframe
              className="video"
              src="https://player.vimeo.com/video/668045818?h=fd596bd55f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              allow="autoplay; fullscreen; picture-in-picture"
              title="SOCAR NEXT MOVE_다음 시대의 이동 비전"></iframe>
          </div>
        </div>
      </section>
      <section className="partners">
        <div className="wrap">
          <h2>Mobility Ecosystem</h2>
          <p>
            1,200만 이용자의 더 나은 이동을 위해
            <br className="non-mobile-br" /> 쏘카와 파트너들은 새로운 모빌리티
            생태계를 만들고 있습니다
          </p>
        </div>
        <div className="wrap card-container">
          <div
            className={vcncSelect ? "vcnc act" : "vcnc"}
            onClick={toggleClass}>
            <dl>
              <dt>
                <h3>VCNC</h3>
                <button
                  role="switch"
                  className="more"
                  aria-checked={vcncSelect ? "true" : "false"}>
                  <img
                    src="/images/company/partners-arrow.svg"
                    alt="자세히보기"
                  />
                </button>
              </dt>
              <dd>
                VCNC는 2011년에 설립된 플랫폼
                <br /> 서비스 기업입니다.
                <br /> 2018년 라이드헤일링 서비스
                <br /> ‘타다’를 출시해 일상 이동의
                <br /> 새로운 기준을 만들어 가고 있습니다.
              </dd>
            </dl>
          </div>
          <div
            className={chacareSelect ? "chacare act" : "chacare"}
            onClick={toggleClass}>
            <dl>
              <dt>
                <h3>차케어</h3>
                <button
                  role="switch"
                  className="more"
                  aria-checked={chacareSelect ? "true" : "false"}>
                  <img
                    src="/images/company/partners-arrow.svg"
                    alt="자세히보기"
                  />
                </button>
              </dt>
              <dd>
                차케어는 차량관리 전문기업입니다.
                <br /> 2019년 쏘카의 자회사가 되어
                <br /> 쏘카 차량의 위생과 안전을 관리하고
                <br /> 있습니다. 출장세차 O2O 플랫폼 운영
                <br /> 경험과 노하우를 기반으로 법인 및 단체,
                <br /> 개인 차량 등에 전문 관리 서비스를
                <br /> 제공합니다.
              </dd>
            </dl>
          </div>
          <div
            className={nine2oneSelect ? "nine2one act" : "nine2one"}
            onClick={toggleClass}>
            <dl>
              <dt>
                <h3>나인투원</h3>
                <button
                  role="switch"
                  className="more"
                  aria-checked={nine2oneSelect ? "true" : "false"}>
                  <img
                    src="/images/company/partners-arrow.svg"
                    alt="자세히보기"
                  />
                </button>
              </dt>
              <dd>
                나인투원은 퍼스널 모빌리티
                <br /> 기술 스타트업입니다.
                <br /> 2018년 국내 최초로 공유전기자전거
                <br /> 서비스 ‘일레클’을 출시했습니다.
                <br /> 서울, 제주, 세종 등 전국 주요 도시에서 안전하고 혁신적인
                이동 경험을
                <br /> 제공하고 있습니다.
                <br /> 2021년 쏘카의 자회사가 되었습니다.
              </dd>
            </dl>
          </div>
        </div>
        <div className="wrap card-container">
          <div
            className={moduCompanySelect ? "modu-company act" : "modu-company"}
            onClick={toggleClass}>
            <dl>
              <dt>
                <h3>모두컴퍼니</h3>
                <button
                  role="switch"
                  className="more"
                  aria-checked={moduCompanySelect ? "true" : "false"}>
                  <img
                    src="/images/company/partners-arrow.svg"
                    alt="자세히보기"
                  />
                </button>
              </dt>
              <dd>
                모두컴퍼니는 주차 중개 플랫폼
                <br /> 기업입니다. 온라인 주차 중개 플랫폼
                <br /> ‘모두의 주차장’을 출시해 주차장 정보
                <br /> 안내, 주차 제휴, 스마트 주차 등의
                <br /> 서비스를 제공하고 있습니다.
                <br /> 2021년 쏘카의 자회사가 되었습니다.
              </dd>
            </dl>
          </div>
          <div
            className={ridefluxSelect ? "rideflux act" : "rideflux"}
            onClick={toggleClass}>
            <dl>
              <dt>
                <h3>라이드플럭스</h3>
                <button
                  role="switch"
                  className="more"
                  aria-checked={ridefluxSelect ? "true" : "false"}>
                  <img
                    src="/images/company/partners-arrow.svg"
                    alt="자세히보기"
                  />
                </button>
              </dt>
              <dd>
                라이드플럭스는 자율주행 기술
                <br /> 스타트업입니다. 완전자율주행에
                <br /> 필요한 풀 스택(full stack) 소프트웨어를 개발합니다. 현재
                쏘카와 함께 제주공항과
                <br /> 중문 관광단지를 오가는 유상 자율주행
                <br /> 운송 서비스를 운영하고 있습니다.
              </dd>
            </dl>
          </div>
        </div>
      </section>
      <section className="history">
        <div className="wrap">
          <div className="title-wrap">
            <h2>History</h2>
            <p>쏘카는 오늘도 모빌리티의 역사를 새롭게 쓰고 있습니다</p>
          </div>
        </div>
        <div className="history-list">
          <div className="wrap">
            <dl>
              <dt>2021</dt>
              <dd>
                <ul>
                  <li>나인투원 인수</li>
                  <li>모두컴퍼니 인수</li>
                  <li className="pad-top">패스포트 출시</li>
                  <li>쏘카카드 출시</li>
                  <li>타다 넥스트 출시</li>
                  <li>국내 최장 유상 자율주행셔틀 시작</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>2020</dt>
              <dd>
                <ul>
                  <li>캐스팅 출시</li>
                  <li>타다 라이트 출시</li>
                  <li className="pad-top">자율주행셔틀 시작</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>2019</dt>
              <dd>
                <ul>
                  <li>차케어 인수</li>
                  <li>폴라리언트 인수</li>
                  <li className="pad-top">쏘카 비즈니스 출시</li>
                  <li>쏘카 플랜 출시</li>
                  <li>쏘카패스 출시</li>
                </ul>
              </dd>
            </dl>
            <div className={btnSelect ? "" : "hide"}>
              <dl>
                <dt>2018</dt>
                <dd>
                  <ul>
                    <li>VCNC 인수</li>
                    <li>라이드플럭스 투자</li>
                    <li className="pad-top">타다 출시</li>
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>2017</dt>
                <dd>
                  <ul>
                    <li>부름 출시</li>
                    <li>쏘카 마이존 출시</li>
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>2012</dt>
                <dd>
                  <ul>
                    <li>카셰어링 서비스 쏘카 출시</li>
                  </ul>
                </dd>
              </dl>
            </div>
            {!btnSelect && (
              <button className="more" onClick={toggleBtnClass}>
                <span className={btnSelect ? "hide" : ""}>더보기</span>
                <img src="/images/company/arrow.svg" alt="연혁 펼치기" />
              </button>
            )}
            {btnSelect && (
              <dl className="first">
                <dt>2011</dt>
                <dd>
                  <ul>
                    <li>
                      <button onClick={toggleBtnClass}>
                        <span>(주)쏘카 설립</span>
                        <img src="/images/company/arrow.svg" alt="연혁 접기" />
                      </button>
                    </li>
                  </ul>
                </dd>
              </dl>
            )}
          </div>
        </div>
      </section>
      <section className="profile">
        <div className="wrap">
          <h2>기업 소개서 다운로드</h2>
          <div className="link-wrap">
            <a
              href="../static/pdf/socar-profile-kor-220328.pdf"
              target="_blank"
              rel="noopener noreferrer">
              한글
            </a>
            <a
              href="../static/pdf/socar-profile-eng-220328.pdf"
              target="_blank"
              rel="noopener noreferrer">
              English
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Company;
